<template>
  <div class="label-marketing-page">
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :head-height="36"
      :success-duration="1300"
    >
      <template #loading>
        <div class="refresh-box">
          <img
            class="loading-iocn"
            src="../../../../assets/img/quickSend/loading.svg"
            alt="加载"
          />
          <span>加载中</span>
        </div>
      </template>
      <template #success>
        <div class="animate-refresh">
          <span>最新时间</span>
          &nbsp;
          <span>{{ nowDate() }}</span>
        </div>
      </template>
      <!-- <div class="markertig-list" v-if="list.length > 0 || refreshing"> -->
      <div class="markertig-list">
        <van-list
          v-model:loading="loading"
          v-model:error="error"
          :finished="finished"
          :immediate-check="false"
          error-text="请求失败，点击重新加载"
          :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
          @load="onload"
        >
          <div
            v-if="!loading && list.length == 0 && finished"
            class="no-data-page"
          >
            <div class="no-data-content">
              <img
                src="@/assets/img/quickSend/talk_nodata.svg"
                alt="暂无数据"
              />
              <p class="no-data-hint">暂无标签营销</p>
            </div>
          </div>
          <LabelMarketingItem
            v-for="(item, index) in list"
            :key="index"
            :marketingItem="item"
            :customer_userid="customer_id"
            :chat_id="chat_id"
            :member_userid="member_userid"
          />
        </van-list>
      </div>
      <!-- <div v-else class="no-data-page">
        <div class="no-data-content">
          <img src="@/assets/img/quickSend/talk_nodata.svg" alt="暂无数据" />
          <p class="no-data-hint">暂无标签营销</p>
        </div>
      </div> -->
    </van-pull-refresh>
  </div>
</template>
<script>
import moment from 'moment'
import { PullRefresh, List } from 'vant'
import LabelMarketingItem from '@/components/quickSend/LabelMarketingItem.vue'
import { labelMarketings } from './test'
import { getMaketingLabelList } from '../../../../service/quickSend.service.js'
import {
  getCurExternalChat,
  getCurExternalContact,
  getCurExternalContactPolling
} from '../../../../utils/nwechat'
export default {
  components: {
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    LabelMarketingItem
  },
  data() {
    return {
      list: [],
      customer_id: '',
      member_userid: '',
      chart_id: '',
      refreshing: false,
      hasAuthority: false,
      finished: false,
      loading: false,
      error: false,
      requestStatus: null,
      pollingIndex: 0
    }
  },
  mounted() {
    // console.log('列表数据', labelMarketings)
    // this.list = labelMarketings.data
    this.init()
  },

  beforeDestroy() {
    // 清除定时器
    clearInterval(this.requestStatus)
  },

  methods: {
    // 页面初始化
    async init() {
      // 成员id
      let userInfo = JSON.parse(localStorage.getItem('vuex'))
      this.member_userid = userInfo.user.info.qw_userid
      //获取聊天群,外部id
      // try {
      //   let customerId = await getCurExternalContactPolling()
      //   this.customer_id = customerId
      //   // 调试--本地
      //   // this.customer_id = 'wm1DICCQAAaO7NrD5ttDF1PpUv5uQqIA'
      //   console.log('客户id: ', this.customer_id)
      // } catch (e) {
      //   let chartId = await getCurExternalChat()
      //   this.chat_id = chartId
      //   // this.onload()
      // } finally {
      //   this.onload()
      // }

      // 轮询请求id
      this.requestStatus = setInterval(() => {
        if (this.customer_id || this.chat_id || this.pollingIndex > 5) {
          this.onload()
          clearInterval(this.requestStatus)
          return
        }
        console.log('现在是第', this.pollingIndex)
        this.pollingIndex++

        // 本地调试
        // this.customer_id = 'wm1DICCQAAtDlnvMCdXM3mRinwuM1zLg'

        getCurExternalContact()
          .then((res) => {
            this.customer_id = res
          })
          .catch(() => {
            this.customer_id = ''
          })
        getCurExternalChat()
          .then((res) => {
            this.chat_id = res
          })
          .catch(() => {
            this.chat_id = ''
          })
      }, 300)
    },
    // 加载列表(无翻页)
    async onload() {
      if (!this.customer_id) {
        this.finished = true
        return
      }
      try {
        this.loading = true
        const { data, code } = await getMaketingLabelList({
          external_userid: this.customer_id
        })

        if (code !== 200) {
          this.error = true
          throw code
        }

        // this.count = data.total_num || 0
        this.list = data
        // this.list = this.list.concat(data.list)
        this.loading = false
      } catch (error) {
        this.error = true
        this.finished = true
        console.log(error)
      } finally {
        this.loading = false
        this.finished = true
        return Promise.resolve(true)
      }
    },
    async onRefresh() {
      this.list = []
      this.refreshing = true
      await this.onload()
      // console.log('刷新被调用了')
      this.refreshing = false
    },

    // 刷新条时间
    nowDate() {
      return moment(new Date()).format('HH:mm:ss')
    }
  }
}
</script>

<style lang="less" scoped>
.label-marketing-page {
  height: calc(100vh - 66px);
  background-color: #f5f5f5;
  overflow-y: scroll;
  .markertig-list {
    padding-top: 8px;
    min-height: calc(100vh - 66px);
  }
}
</style>
