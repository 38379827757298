<template>
  <div class="label-marketing-item">
    <div @click="openMarkting" class="top-markting-label">
      <div class="label-list-box" ref="labelList">
        <div
          v-for="(labelItem, index) in marketingItem.tag_arr"
          :key="index"
          :class="[
            'label-item',
            labelItem.tag_group_type == 'HQ' ? 'la-headquarters' : 'la-service'
          ]"
          :ref="'labelItem' + index"
        >
          <div class="label-title txt-line-3">
            {{ labelItem.tag_group_name }}
          </div>
          <div class="label-value">
            <p class="txt-line-3">
              {{ labelItem.tag_name }}
            </p>
          </div>
        </div>
        <div class="more-label" v-if="moreLabels">...</div>
      </div>
      <!-- <div class="label-open-btn"> -->
      <div :class="['open-btn', isOpen ? 'open-btn-active' : '']">
        <van-icon name="play" size="16px" />
      </div>
      <!-- </div> -->
    </div>
    <div class="markting-send-list" v-if="isOpen">
      <!-- <sendListItem v-for="item in 3"></sendListItem> -->
      <!-- <sendListItem
              v-for="(sItem, sIndex) in labelSendList"
              :sendItem="sItem"
              pageType="label"
              :mediaList="sItem.file_arr"
              :customer_userid="customer_userid"
              :member_userid="member_userid"
              :index="sIndex"
              :key="sIndex"
              cutColor="#f5f5f5"
            ></sendListItem> -->
      <sendListItem
        v-for="(item, index) in labelSendList"
        :key="index"
        :customer_userid="customer_userid"
        :chat_id="chat_id"
        :index="index"
        :labelSpeechItem="marketingItem"
        :limitNum="5"
        :mediaList="item.file_arr"
        :member_userid="member_userid"
        :sendItem="item"
        backgroundColor="#fff"
        cutColor="#f5f5f5"
        pageType="label"
        isSupportEdit
        :tag_id_arr="marketingItem.tag_id_arr"
        @onEdit="onEdit"
      />
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant'
import { speechList } from '../../views/tool/marketing/tabPage/test'
import { res } from '../../views/tool/marketing/tabPage/test'
import sendListItem from './sendListItem.vue'
import { getLabelSpeechList } from '../../service/quickSend.service.js'

export default {
  components: {
    [Icon.name]: Icon,
    sendListItem: sendListItem
  },
  props: {
    marketingItem: {
      type: Object,
      defualt() {
        return {}
      }
    },
    customer_userid: {
      // 成员id
      type: String,
      default() {
        return ''
      }
    },
    member_userid: {
      // 外部id(客户id)
      type: String,
      default() {
        return ''
      }
    },
    chat_id: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      isOpen: false,
      labelSendList: [],
      moreLabels: true,
      copyLabelList: []
    }
  },
  created() {
    // this.labelSendList = speechList.data
    // this.limitLabelList()
    this.init()
  },
  async mounted() {
    this.$nextTick(() => {
      this.limitLabelList()
    })
  },

  methods: {
    init() {
      this.copyLabelList = JSON.parse(
        JSON.stringify(this.marketingItem.tag_arr)
      )
      return Promise.resolve(true)
    },
    async openMarkting() {
      // this.labelSendList = res.data
      // console.log('ccc', this.labelSendList)
      let { data } = await getLabelSpeechList({
        tag_id_arr: this.marketingItem.tag_id_arr
      })
      this.labelSendList = data
      this.isOpen = !this.isOpen
    },
    // 根据标签宽度来控制显示标签数量
    limitLabelList() {
      if (this.marketingItem.tag_arr.length < 2) {
        return
      }

      let firstWidth = this.$refs.labelItem0.offsetWidth
      let secoundWidth = this.$refs.labelItem1.offsetWidth
      // let viewWidth = document.body.clientWidth
      let viewWidth = this.$refs.labelList.offsetWidth
      let wholeWidth = firstWidth + secoundWidth
      // 第一个或第二个过长只展示第一个
      if (
        firstWidth > (viewWidth - 28) / 2 ||
        secoundWidth > (viewWidth - 28) / 2
      ) {
        this.moreLabels = true
        let arr = []
        arr[0] = this.marketingItem.tag_arr[0]
        this.marketingItem.tag_arr = arr
        // console.log('------', this.marketingItem)
      } else if (
        wholeWidth > (viewWidth - 28) / 2 &&
        wholeWidth < viewWidth - 28
      ) {
        this.moreLabels = true
        this.marketingItem.tag_arr = this.marketingItem.tag_arr.slice(0, 2)
      }
    },
    // 编辑事件
    onEdit({ sale_script_id }) {
      const { chat_id, customer_userid } = this
      this.$router.push({
        name: 'taskEdit',
        query: {
          pageType: 'label',
          sale_script_id,
          chat_id,
          customer_userid
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.label-marketing-item {
  margin-top: 1px;

  .top-markting-label {
    padding: 12px 15px;
    background-color: #fff;
    display: flex;
    margin-top: 1px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  }
}

.label-list-box {
  display: flex;
  flex-wrap: wrap;
  max-height: 54px;
  overflow: hidden;

  .label-item {
    // flex-grow: 0;
    // display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    line-height: 16px;
    align-items: center;
    font-size: 12px;
    border-radius: 2px;
    max-height: 54px;
    margin-bottom: 5px;
    margin-right: 8px;

    .label-title {
      // flex: 1;
      padding: 2px 4px;
      max-width: 128px;
      border-radius: 2px 0px 0px 2px;
      overflow: hidden;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      // white-space: nowrap;
    }

    .label-value {
      // flex: 1;
      color: #fff;
      padding: 2px 8px;
      max-width: 136px;
      // border-radius: 0px 2px 2px 0px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .more-label {
    color: #1773fa;
    display: flex;
    align-items: flex-end;
    margin-left: 8px;
    font-size: 14px;
  }

  // 总部标签
  .la-headquarters {
    border: 1px solid #1773fa;

    .label-title {
      color: #1773fa;
      // border: 1px solid #1773fa;
    }

    .label-value {
      background-color: #1773fa;
      // border: 1px solid #1773fa;
    }
  }

  //服务标签
  .la-service {
    border: 1px solid #ff6f00;

    .label-title {
      color: #ff6f00;
      // border: 1px solid #ff6f00;
    }

    .label-value {
      background-color: #ff6f00;
      // border: 1px solid #ff6f00;
    }
  }

  /*.label-item + .label-item {*/
  /*  margin-right: 8px;*/
  /*}*/
}

// 下拉按钮
.open-btn {
  width: 16px;
  height: 16px;
  // margin-left: 21px;
  // margin-right: 3px;
  transform: rotate(90deg);
  transition: all 0.3s;
}

.open-btn-active {
  transform: rotate(-90deg);
  transition: all 0.3s;
}

// 打开标签营销下拉
.markting-send-list {
  // padding: 20px 15px;
  padding: 20px 15px 0 15px;
}
</style>
