<template>
  <div class="knownledge-page">
    <!-- <div class="knownledge-search">
      <van-search
        v-model="value"
        placeholder="搜索"
        input-align="center"
        @click-input="handleSearch"
      />
    </div> -->
    <van-sticky :offset-top="65">
      <div class="imitate-search-box">
        <div class="imitate-input" @click="handleSearch">
          <van-icon
            :name="require('@/assets/img/common/search.svg')"
            color="#999"
            size="16"
          />
          <span class="imitate-placehodor">搜索</span>
        </div>
      </div>
    </van-sticky>
    <div>
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :head-height="36"
        :success-duration="1300"
        :disabled="refreshDisabled"
      >
        <template #loading>
          <div class="refresh-box">
            <img
              class="loading-iocn"
              src="../../../../assets/img/quickSend/loading.svg"
              alt="加载"
            />
            <span>加载中</span>
          </div>
        </template>
        <template #success>
          <!-- <div class="refresh-box animate-refresh"> -->
          <div class="animate-refresh">
            <span>最新时间</span>
            &nbsp;
            <span>{{ nowDate() }}</span>
          </div>
        </template>
        <div v-show="groupList.length" class="knowledge-content">
          <van-sticky :offset-top="114" class="group-menu-box">
            <div class="group-menu" ref="tableScorll">
              <van-sidebar v-model="activeSiderBar" @change="changeGroup">
                <van-sidebar-item
                  v-for="siderItem in groupList"
                  :title="siderItem.name"
                />
              </van-sidebar>
            </div>
          </van-sticky>
          <div class="knowledge-list">
            <sendListItem
              v-for="(item, index) in list"
              :sendItem="item"
              pageType="knowledge"
              isSupportEdit
              :limitNum="3"
              :mediaList="item.file_arr"
              :customer_userid="customer_userid"
              :chat_id="chat_id"
              :member_userid="member_userid"
              :index="index"
              :key="index"
              cutColor="#fff"
              @onEdit="onEdit"
            ></sendListItem>
          </div>
        </div>
        <div v-show="groupList.length === 0" class="no-data-page">
          <div class="no-data-content">
            <img src="@/assets/img/quickSend/kn_nodata.svg" alt="暂无数据" />
            <p class="no-data-hint">暂无知识</p>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Sticky, Sidebar, SidebarItem, PullRefresh, Icon } from 'vant'
import sendListItem from '../../../../components/quickSend/sendListItem.vue'
import {
  getGroupList,
  getKnowledgeList
} from '../../../../service/quickSend.service'
import { getCurExternalContact } from '../../../../utils/nwechat.js'
import moment from 'moment'
// import { res } from './test'
export default {
  components: {
    // [Search.name]: Search,
    [Sticky.name]: Sticky,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [PullRefresh.name]: PullRefresh,
    [Icon.name]: Icon,
    sendListItem: sendListItem
  },
  props: {
    chat_id: {
      type: [String, Number],
      default: ''
    },
    customer_userid: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      value: '',
      activeSiderBar: 0,
      params: {
        group_id: '',
        search_content: ''
      },
      loading: false,
      error: false,
      finished: false,
      refreshing: false,
      list: [],
      groupParams: {
        type: 0,
        get_sale_script_num: 1,
        search_content: '',
        search_group_name: ''
      },
      groupList: [],
      // customer_userid: '',
      member_userid: '',
      refreshDisabled: false,
      scrollTop: 0
    }
  },
  mounted() {
    this.init()
    // console.log(this.$refs)
    let tableScorll = this.$refs.tableScorll
    tableScorll.addEventListener('touchmove', () => {
      this.scrollTop = tableScorll.scrollTop
    })
    tableScorll.addEventListener('touchend', () => {
      this.scrollTop = tableScorll.scrollTop
    })
  },
  watch: {
    scrollTop(val) {
      if (val <= 0) {
        this.refreshDisabled = false
      } else {
        this.refreshDisabled = true
      }
      // console.log('asdadasd')
      this.$emit('refresh', this.refreshDisabled)
    }
  },
  methods: {
    async init() {
      this.getknGroupList()
      // // 客户id
      // try {
      //   let res = await getCurExternalContact()
      //   this.customer_userid = res
      // } catch (e) {
      //   console.log(e)
      // }
    },
    // 跳转搜索页
    handleSearch() {
      this.$router.push({
        path: '/knowledge-search',
        query: {
          chat_id: this.chat_id || '',
          customer_userid: this.customer_userid || ''
        }
      })
    },

    // 下拉刷新
    async onRefresh() {
      this.refreshing = true
      // 分组初始化
      // console.log(this.groupList[0].group_id)
      // this.params.group_id = this.groupList[0].group_id
      this.activeSiderBar = 0
      // console.log('选中', this.activeSiderBar, this.groupList[0].name)
      await this.getknGroupList()
      // this.refreshing = false
    },
    // 获取分组
    async getknGroupList() {
      let { data, code } = await getGroupList(this.groupParams)
      // console.log('分组列表', data)
      if (code == 200) {
        // 过滤掉没有知识的分组
        let obj = data.filter((item) => {
          return item.sale_script_num !== 0
        })
        this.groupList = obj || []
        // this.groupList = data || []
        this.params.group_id = this.groupList[0].group_id
        // this.activeSiderBar = this.groupList[0].name
        this.onload()
        // return Promise.resolve(true)
      }
    },
    // 单页数据（无分页最多20条）
    async onload() {
      this.list = []
      try {
        this.loading = true
        const { data, code } = await getKnowledgeList(this.params)
        // console.log('获取的数据', data)
        if (code !== 200) {
          this.error = true
          this.loading = false
          throw code
        }
        // console.log(data)
        // 暂无数据
        if (data.length === 0) {
          this.loading = false
          this.finished = true
        }
        this.list = data || []
        this.loading = false
      } catch (error) {
        this.error = true
        console.log(error)
      } finally {
        // return Promise.resolve(true)
        this.refreshing = false
      }
    },
    // 刷新条时间
    nowDate() {
      return moment(new Date()).format('HH:mm:ss')
    },
    // 切换知识分组
    changeGroup(index) {
      // 清除之前的选中样式
      this.groupList.forEach((item, mindex) => {
        if (this.params.group_id == item.group_id) {
          let oldPreItem = document.querySelector(
            `.group-menu .van-sidebar-item:nth-child(${mindex})`
          )
          if (oldPreItem) oldPreItem.style = 'border-bottom-right-radius: 0px;'
        }
      })

      // console.log('侧边栏切换了', index)
      this.params.group_id = this.groupList[index].group_id
      this.onload()
      // 列表滚动条回到顶部
      document.querySelector('.knowledge-list').scrollTop = 0
      document.querySelector('.content').scrollTop = 0

      // 选中样式（选中的模块相邻模块显示圆角）
      let preItem = document.querySelector(
        `.group-menu .van-sidebar-item:nth-child(${index})`
      )
      if (preItem) preItem.style = 'border-bottom-right-radius: 8px;'
    },
    // 编辑事件
    onEdit({ sale_script_id }) {
      const { chat_id, customer_userid } = this
      this.$router.push({
        name: 'taskEdit',
        query: {
          pageType: 'knowledge',
          sale_script_id,
          chat_id,
          customer_userid
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/style/imitate_search.less');
.knownledge-page {
  min-height: calc(100vh - 66px);
  background-color: #f5f5f5;

  .knownledge-search {
    margin-top: 1px;
  }

  .knowledge-content {
    margin-top: 1px;
    display: flex;

    .group-menu-box {
      box-sizing: border-box;
      width: 21.33vw;
      :deep(.van-sticky) {
        width: 21.33vw;
      }
    }
    .group-menu {
      width: 21.33vw;
      // width: 100%;
      height: calc(100vh - 123px);
      overflow-y: scroll;
      background-color: #f5f5f5;
    }
    .knowledge-list {
      box-sizing: border-box;
      // width: 295px;
      width: 78.67vw;
      background-color: #fff;
      min-height: calc(100vh - 115px);
      padding: 23px 15px;
      overflow-y: scroll;
    }
  }

  // .no-data-page {
  //   margin-top: 1px;
  //   height: calc(100vh - 123px);
  //   background-color: #f5f5f5;
  //   display: flex;
  //   justify-content: center;
  //   // padding-top: 150px;
  //   align-items: center;
  //   .no-data-content {
  //     margin-top: -123px;
  //     img {
  //       width: 110px;
  //       height: 118px;
  //     }
  //     .no-data-hint {
  //       margin-top: 13px;
  //       font-size: 14px;
  //       line-height: 20px;
  //       color: #909399;
  //       text-align: center;
  //     }
  //   }
  // }
}
</style>
<style lang="less">
// 更改vant默认样式
.knownledge-page .van-pull-refresh {
  overflow: visible;
  .van-pull-refresh__head {
    z-index: -1;
  }
}
.knownledge-page .group-menu {
  // background-color: #fff;
  .van-sidebar {
    width: 100%;
    background-color: #fff;
  }
  .van-sidebar-item {
    padding: 0 10px;
    display: flex;
    align-items: center;
    min-height: 50px;
    // background-color: #f5f5f5;
  }
  // 选中样式
  .van-sidebar-item--select + .van-sidebar-item {
    border-top-right-radius: 8px;
  }
  .van-sidebar-item__text {
    font-size: 12px;
    line-height: 17px;
    // white-space: ;
  }
  .van-sidebar-item--select:before {
    display: none;
  }
}
</style>
